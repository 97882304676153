import dmshttp from "@/utils/dmsrequest";

export function getList(data) {
  return dmshttp({
    url: 'getSalesOutInveentoryFast',
    data
  })
}
export function salesOrderOut(data) {
  return dmshttp({
    url: 'salesOrderBatchOut',
    method:'post',
    data
  })
}
