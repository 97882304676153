import bg from "@/assets/purchaseOrder/bg.png";
import moment from "moment";
import { getList, salesOrderOut } from "./api";
import { getDeliveryAmountFind, getTOF } from "@/views/stockCenter/adjustmentApply/allot/api";

moment.locale("zh-cn");
export default {

  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/channelOrder/orderList",
          name: "orderList",
          title: "销售订单（审核出库）"
        },
        {
          path: "/channelOrder/quickDelivery",
          name: "quickDelivery",
          title: "快速出库"
        }
      ],
      bg: bg,
      columns: [
        {
          title: "仓库",
          dataIndex: "gicWarehouseName",
          ellipsis: true
        },
        {
          title: "产品型号",
          dataIndex: "model",
          ellipsis: true
        },
        {
          title: "物料号",
          dataIndex: "material",
          ellipsis: true
        },
        {
          title: "质量等级",
          dataIndex: "invStatusName",
          ellipsis: true
        },
        {
          title: "补差类型",
          dataIndex: "invStatusTypeName",
          ellipsis: true
        },
        {
          title: "本次出库数量",
          dataIndex: "qty",
          ellipsis: true
        }
      ],
      isFixed: false,
      orderDetial: {},
      page: {
        pageNo: 1,
        pageSize: 5
      },
      loadings: false,//loading效果
      loadMores: false,//查看更多
      noMore: false,//没有更多控制
      nolistImg: require("@/assets/order/noList.png"),
      NoDate: false,// 没有数据控制
      pageLoading: false,// 页面加载loading
      checked: false,// 全选按钮loading
      allLoading: false,// 批量出库按钮loading
      visibleAAA:false,
      msgStatus:''
    };
  },
  mounted() {
    window.addEventListener("scroll", this.throttle(this.handleScroll, 100), true);
    this.getDetails();
  },
  methods: {
    // 全选按钮
    allCherk(e) {
      this.checked = e.target.checked;
      this.$forceUpdate();
      // 如果当前按钮是true 全部改为选中状态
      if (this.checked == true) {
        if (this.orderDetial.length > 0) {
          this.orderDetial.forEach(item => {
            item.isCherk = true;
            this.$forceUpdate();
          });
        }
      } else {
        if (this.orderDetial.length > 0) {
          this.orderDetial.forEach(item => {
            item.isCherk = false;
            this.$forceUpdate();
          });
        }

      }
    },
    // 批量出库
    allChuku() {
      let num = 0; // 选中的数量
      let IdArr = [];
      // this.orderDetial 数据大于0 进行循环
      if (this.orderDetial.length > 0) {
        this.orderDetial.forEach(item => {
          if (item.isCherk == true) {
            num += 1;
            IdArr.push(item.id);
          }
        });
      }

      if (num <= 0) {
        this.$message.warning("请至少选择一项");
        return;
      } else {
        let data = {
          salesOrderIds: IdArr,
          userAccount: this.$store.state.user.userInfo.account.account
        };
        this.allLoading = true;
        this.$forceUpdate();
        salesOrderOut(data).then(res => {
          let resData = JSON.parse(res.data);
          if (resData.code == 0) {
            this.$message.success(resData.msg);
            this.allLoading = false;
            this.$forceUpdate();
            this.getDetails();
            localStorage.setItem("saleOrderListTime", new Date().getTime());
          } else {
            this.$message.warning(resData.msg);
            this.allLoading = false;
            this.timer = setTimeout(() => {  //设置延迟执行
              //  刷新页面
              this.getDetails();
            }, 5000);
            this.$forceUpdate();
          }
          this.allLoading = false;
          this.$forceUpdate();
        });
      }
    },
    //单条出库
    outWahouse(id, firstItem) {
      let data = {
        salesOrderIds: Array(id),
        userAccount: this.$store.state.user.userInfo.account.account
      };
      firstItem.chukuIsLoading = true;
      this.$forceUpdate();
      salesOrderOut(data).then(res => {
        let resData = JSON.parse(res.data);
        if (resData.code == 0) {
          this.$message.success(resData.msg);
          firstItem.chukuIsLoading = false;
          this.$forceUpdate();
          this.getDetails();
          localStorage.setItem("saleOrderListTime", new Date().getTime());
        } else {
          this.$message.warning(resData.msg);
          firstItem.chukuIsLoading = false;
          this.timer = setTimeout(() => {  //设置延迟执行
            //  刷新页面
            this.getDetails();
          }, 5000);
          this.$forceUpdate();
        }
        firstItem.chukuIsLoading = false;
        this.$forceUpdate();
      });
    },
    // 每一项按钮选中
    onChangeOrder(item, e) {
      item.isCheck = e.target.checked;
      this.$forceUpdate();
      let num = 0, IdArr = [];
      if (this.orderDetial.length > 0) {
        this.orderDetial.forEach(item => {
          if (item.isCherk == true) {
            num += 1;
            IdArr.push(item.id);
          }
        });
      }
      // 选中数量的数量和接口数量一致  全选按钮为选中状态  否则就取消选中状态
      if (num == this.orderDetial.length) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },

    // 加载更多 暂时没用
    loadMore() {
      this.page.pageNo++;
      this.getDetails();
    },
    // 获取列表数据
    getDetails() {
      let data = {
        "terms": {
          "canOut": "1"
        },
        page: this.page
      };
      this.pageLoading = true;
      getList(data).then(res => {
        let realDate = JSON.parse(res.data);
        if (realDate && realDate.data && realDate.data.length > 0) {
          if (res && res.data) {
            let realDate = JSON.parse(res.data);

            // this.orderDetial = realDate.data;
            if (realDate.data.length > 0) {
              // 如果获取的数据为0  当前页数不为1
              if (realDate.data.length == 0 && this.page.pageNo != 1) {
                this.noMore = true;
                this.loadMores = false;
                //没有更多数据
              } else if (realDate.data.length != 0 && this.page.pageNo != 1) {
                this.orderDetial = this.orderDetial.concat(realDate.data);
                this.$forceUpdate();
              } else if (realDate.data.length != 0 && this.page.pageNo == 1) {
                this.orderDetial = realDate.data;
                this.NoDate = false;
                this.noMore = false;
                this.loadMores = true;

              } else if (realDate.data.length == 0 && this.page.pageNo == 1) {
                this.NoDate = true;
                this.noMore = false;
                this.loadMores = false;
              }

              if (realDate.data && realDate.data.length != 0 && this.page.pageNo * this.page.pageSize >= realDate.page.totalCount) {
                this.noMore = true;
                this.loadMores = false;
              }

            }
            if (this.orderDetial.length > 0) {
              this.orderDetial.forEach(item => {
                item.chukuIsLoading = false,
                  item.isCherk = false;
              });
            }
          }
        } else {
          // 如果不返回数据 则展示没有数据的样式
          this.NoDate = true;
        }
        this.pageLoading = false;
      });
    },
    // 滚动页面
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    // 滚动
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    }
  }
};